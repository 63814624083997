*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    overflow: hidden;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.experience {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
}

.loading-bar {
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    background: #ffffff;
    transform: scaleX(0.3);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended {
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}